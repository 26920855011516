import { FC, useCallback, useEffect, useState } from 'react';
import DateUtils from '../../../utils/DateUtils';
import ClientFormVersionHistory, { VersionState } from '../../../models/ClientFormVersionHistory';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { goToClientForm } from '../../../utils/NavigationUtils';
import VersionStateLabel from './VersionStateLabel';
import { interpolate } from '../../../utils/interpolation/InterpolationUtils';
import { useCurrentFormVersionState } from '../../../global-state/Forms';
import { useCurrentUser } from '../../../global-state/Auth';
import { useCurrentClient } from '../../../global-state/Clients';

type GroupedHistory = {
  parent: ClientFormVersionHistory;
  children: ClientFormVersionHistory[];
};

type TimelineProps = {
  items: GroupedHistory[];
  activeVersion: number;
  originId: string | null;
};

type VersionItemProps = { item: GroupedHistory; isLatest: boolean; isActive: boolean; state: VersionState; originId: string | null };

const VersionItem: FC<VersionItemProps> = (props) => {
  const { item, state, isLatest, isActive, originId } = props;
  const { t } = useTranslation(['documents', 'common', 'form']);
  const [showMore, setShowMore] = useState(false);
  const setFormVersionState = useCurrentFormVersionState((x) => x.setValue);

  const navigate = useNavigate();
  const currentUser = useCurrentUser((x) => x.value);
  const currentClient = useCurrentClient((x) => x.value);
  const openVersion = useCallback(() => {
    const id = isLatest ? originId : item.parent.clonedClientFormId;
    id && currentClient && goToClientForm({ id: id, clientId: currentClient.id }, currentUser, navigate);
  }, [originId, currentClient, currentUser, isLatest, item.parent.clonedClientFormId, navigate]);

  useEffect(() => {
    if (isActive || isLatest) {
      setFormVersionState(state);
    }
  }, [isActive, isLatest, setFormVersionState, state]);

  return (
    <li
      onClick={openVersion}
      className={`group ${item.parent.clonedClientFormId || originId ? 'cursor-pointer hover:bg-gray-100' : ''} ${
        isActive ? 'bg-accent-light-mid' : ''
      }`}
    >
      <div className="flex flex-row">
        <div className="flex flex-col items-center justify-around">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="my-1 h-6 w-6" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.66676 5.27148C8.66676 4.90324 8.36824 4.60472 8 4.60472C7.63176 4.60472 7.33324 4.90324 7.33324 5.27148H8.66676ZM8 7.79876H7.33324C7.33324 7.98182 7.4085 8.15683 7.54138 8.28274L8 7.79876ZM9.54167 10.1782C9.80897 10.4315 10.231 10.4201 10.4843 10.1528C10.7376 9.88553 10.7262 9.46351 10.4589 9.21023L9.54167 10.1782ZM7.33324 5.27148V7.79876H8.66676V5.27148H7.33324ZM7.54138 8.28274L9.54167 10.1782L10.4589 9.21023L8.45862 7.31477L7.54138 8.28274ZM13.335 7.79867C13.335 10.5376 10.9811 12.8183 8.00086 12.8183V14.1518C11.649 14.1518 14.6685 11.3408 14.6685 7.79867H13.335ZM8.00086 12.8183C5.02065 12.8183 2.66676 10.5376 2.66676 7.79867H1.33324C1.33324 11.3408 4.35271 14.1518 8.00086 14.1518V12.8183ZM2.66676 7.79867C2.66676 5.05979 5.02065 2.77907 8.00086 2.77907V1.44554C4.35271 1.44554 1.33324 4.25657 1.33324 7.79867H2.66676ZM8.00086 2.77907C10.9811 2.77907 13.335 5.05978 13.335 7.79867H14.6685C14.6685 4.25657 11.649 1.44554 8.00086 1.44554V2.77907Z"
              fill={isLatest ? '#008484' : '#96939D'}
            />
          </svg>

          <div className="border-gray-4 h-full border-l"></div>
        </div>
        <div className="ml-2 flex flex-col pb-2 pr-2">
          <div className="flex w-full items-center">
            <span className={`${isActive || (!originId && isLatest) ? 'text-primary-1 font-medium' : 'text-light-gray-1'}`}>
              {t('form:info-modal.history.heading.version')} {item.parent.majorVersion}
            </span>
            <div>
              <VersionStateLabel state={state} />
            </div>
          </div>
          <VersionContent item={item.parent} />

          {showMore && (
            <div>
              {item.children.map((x) => (
                <VersionContent key={x.id} item={x} />
              ))}
            </div>
          )}
          {!!item.children.length && (
            <span
              className="text-dpm-14 cursor-pointer underline"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowMore((prev) => !prev);
              }}
            >
              {showMore ? t('form:info-modal.history.show-less') : t('form:info-modal.history.show-more')}
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

const VersionContent: FC<{ item: ClientFormVersionHistory }> = ({ item }) => {
  return (
    <div className="pb-2">
      <div className="text-dpm-12 text-light-gray-1 no-color">{interpolate(item.activityKey, item.activityPlaceholders)}</div>
      <div className="text-dpm-12">{DateUtils.formatDateTime(new Date(item.createdUtc))}</div>
      <div className="text-dpm-12 text-light-gray-1">{item.remark}</div>
    </div>
  );
};

const VersionTimeline: FC<TimelineProps> = (props) => {
  const { items, activeVersion, originId } = props;
  return (
    <div className="flex flex-col py-2">
      <ul className="list-none">
        {items.map((group, i) => (
          <VersionItem
            key={group.parent.id}
            item={group}
            isLatest={i === 0}
            isActive={activeVersion === group.parent.majorVersion && i > 0}
            state={group.parent.versionState}
            originId={originId}
          />
        ))}
      </ul>
    </div>
  );
};

export default VersionTimeline;
